import { useFormik } from "formik";
import { useRouter } from "next/router";
import { useState } from "react";
import useSWR from "swr";
import * as yup from "yup";
import { useAppContext } from "../../context/useAppContext";
import { accessToken } from "../../auth/auth";
import * as _ from "lodash";
import { swrCommandConfig } from "../../services/swr";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { setRoles } from "../../redux/reducers/configReducer";

const useSignIn = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  if (_.isEmpty(accessToken.get()) == false) {
    router.push("/dashboard");
  }

  const validationSchema = yup.object({
    email: yup
      .string()
      .required("Email is required")
      .matches(/@1337bit\.io$/, "Email must end with @1337bit.io"),
    credentials: yup
      .string()
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  const context = useAppContext();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const fetcher = async (url: string) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "X-Api-Key": "1234",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      credentials: "include",
      body: new URLSearchParams({
        email: formik.values.email,
        credentials: formik.values.credentials,
        client_id: "jwt.web-api",
        client_secret: "secret",
        grant_type: "credentials",
      }),
    });

    const data = await response.json();

    if (response.ok == false) {
      const error = {
        title: "Client Error",
        status: 422,
        message: "Login failed",
        errors: [data.error_description],
      };
      throw error;
    }

    context.setToken(data.access_token);
    accessToken.set(data.access_token);

    var roles = (jwt_decode(data.access_token) as any).role;
    context.setRoles(roles);
    dispatch(setRoles(roles));

    router.push({
      pathname: "/dashboard",
    });
  };

  const { error, isValidating, mutate } = useSWR(
    `${process.env.NEXT_PUBLIC_API_URL}/connect/token`,
    fetcher,
    swrCommandConfig,
  );

  const formik = useFormik({
    initialValues: {
      email: "",
      credentials: "",
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      console.log("values", values);
      mutate();
    },
  });
  return { router, error, isValidating, formik, showPassword, setShowPassword };
};

export default useSignIn;
